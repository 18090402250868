import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import dayjs from "dayjs";
import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Progress,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import request from "_u/request";
import Navbar from "_c/Navbar";
import { LineChart } from "_c/LineChart";
import CVEntry, { entries } from "./AssetCard/CVEntries";
// import USDT from "_icon/USDT";
import BUSD from "_icon/BUSD";
// import DMMC from "_icon/DMMC";
// import GiftIcon from "_icon/GiftIcon";
// import BNB from "_icon/BNB";
// import CoinIcon from "_icon/CoinIcon";
// import FireIcon from "_icon/FireIcon";
import BagIcon from "_icon/BagIcon";
import GameIcon from "_icon/GameIcon";
import GiftIcon from "_icon/GiftIcon";
import IMG_BG from "_img/otc-bg.jpg";
import Entry from "./AssetCard/Entry";
// import useTvlData from "./AssetCard/useTvlData";
import cls from "./index.module.scss";

const Page = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isOpen, onClose } = useDisclosure();
  // const [briefData, setBriefData] = useState({});
  const [dynamicData, setDynamicData] = useState({});
  const [computingPackageData, setComputingPackageData] = useState({});
  const [priceData, setPriceData] = useState([]);
  const [dmmcPrice, setDmmcPrice] = useState("");
  // const { balance: tvl } = useTvlData();

  useEffect(() => {
    getSummaryData();
    return () => {};
  }, []);

  const getSummaryData = () => {
    // request("gm/api/community/brief")
    //   .then((data) => {
    //     data && setBriefData(data);
    //   })
    //   .catch(console.warn);
    request("gm/api/computingpackage/list")
    .then((data) => {
      data[0] && setComputingPackageData(data[0]);
    })
    .catch(console.warn);
    request("gm/api/user/selfassets")
      .then((data) => {
        data && setDynamicData(data);
      })
      .catch(console.warn);
    request("gm/api/dmmc/price").then((data) => {
      if (Array.isArray(data)) {
        const dt = data.sort((a, b) => Number(a.xValue) - Number(b.xValue) <= 0);
        const temp = {
          // labels: dt.map((v) => dayjs(Number(v.xValue)).format("HH:mm")),
          labels: dt.map((v) => ""),

          datasets: [
            {
              label: "Price",
              data: dt.map((v) => v.yValue),
              pointStyle: false,
              borderColor: "#8a62ef",
              min: 0,
            },
          ],
        };
        setPriceData(temp);
        setDmmcPrice(data[data.length - 1]?.yValue);
      }
    });
  };

  // const displayNum = (v, unit) => {
  //   if ([null, "", undefined].includes(v)) return "--";
  //   const isNum = !Number.isNaN(Number(v));
  //   if (v && !isNum) return v;
  //   if (!unit) return v;
  //   if (unit === "$") {
  //     return (
  //       <Text>
  //         <Text as="span" opacity={0.6} fontSize="0.6em">
  //           {unit}
  //         </Text>
  //         &nbsp;{v}
  //       </Text>
  //     );
  //   } else {
  //     return (
  //       <Text>
  //         {v}&nbsp;
  //         <Text as="span" opacity={0.6} fontSize="0.6em">
  //           {unit}
  //         </Text>
  //       </Text>
  //     );
  //   }
  // };

  return (
    <div className={cls.page}>
      <Navbar />
      <Box>
        <Image src={IMG_BG} pos="absolute" w="100%" zIndex={0} />
        <Box p="8" pt={16} pos={"relative"} zIndex={1}>
          <Stack spacing={0} mb={8} className={cls.colorful}>
            <Heading as="h2" size="4xl" color="#fff">
              {t("home.tt1")}
            </Heading>
            <Heading as="h2" size="4xl" color="#fff">
              {t("home.tt2")}
            </Heading>
          </Stack>
        </Box>

        <Box>
          <Box className={cls.cnt}>
            <Box p={8} pt={0}>
              <Heading size="md">{t("home.fork_title")}</Heading>

              <Grid mt="4" templateRows="repeat(1, 1fr)" templateColumns="repeat(2, 1fr)" gap={3}>
                <GridItem rowSpan={1}>
                  <Entry title={t("home.eco_vc")} icon={<BUSD color="#C05621" fontSize="20px" />} value={t("home.preparing")} />
                </GridItem>
                <GridItem rowSpan={1}>
                  <Entry
                    title={t("home.total_amount")}
                    icon={<BagIcon color="#D69E2E" fontSize="20px" />}
                    value={`1${t("home.unit_bil")}`}
                  />
                </GridItem>
                <GridItem rowSpan={1}>
                  <Entry
                    title={t("home.total_power_value")}
                    icon={<GameIcon color="#D69E2E" fontSize="20px" />}
                    value={parseFloat(dynamicData.allPowerValue) || "--"}
                  />
                </GridItem>
                <GridItem rowSpan={1}>
                  <Entry
                    title={t("home.power_value_unit")}
                    icon={<GiftIcon color="#D69E2E" fontSize="20px" />}
                    value={parseFloat(dynamicData.powerValueUnit) || "--"}
                  />
                </GridItem>
              </Grid>
            </Box>

            <Box className={cls.cnt}>
              <Box p={8} pt={0}>
                <Heading size="md">{t("home.dmmts_title")}</Heading>
                <Box mt={4} borderRadius="xl" bg="gray.700" p="4">
                  <Text mt={4}>
                    {t("home.dmmts_progress_title")}
                    {/* // TODO： 修改附文额度 */}
                    {dynamicData.runesAvailable ? `( ${dynamicData.runesAvailable})` : ""}
                  </Text>
                  <Progress
                    mt={2}
                    borderRadius="xl"
                    colorScheme="blue"
                    size="md"
                    // TODO： 修改附文额度
                    value={
                      ((dynamicData.runesTotalAmount - dynamicData.runesAvailable) / dynamicData.runesTotalAmount || 0) * 100
                    }
                  />
                  <Box mt={6}>
                    <Button
                      w="100%"
                      bgGradient="linear(to-r, #0c9ff6, #776bf0, #8a62ef)"
                      colorScheme="blue"
                      onClick={() => navigate("/mineRunes")}
                    >
                      {t("home.runes")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box p={8} pt={0}>
              <Heading size="md">{t("home.dm_asset")}</Heading>
              <Box mt={4} borderRadius="xl" bg="gray.700" p="4">
                <Text mt={4}>
                  {t("home.dynamic_award")}
                  {dynamicData.brokerageLimitAmount
                    ? `(${dynamicData.brokerageLimitAmount - dynamicData.brokerageLeftAmount || 0} / ${
                        dynamicData.brokerageLimitAmount
                      })`
                    : ""}
                </Text>
                <Progress
                  mt={2}
                  borderRadius="xl"
                  colorScheme="blue"
                  size="md"
                  value={
                    ((dynamicData.brokerageLimitAmount - dynamicData.brokerageLeftAmount) / dynamicData.brokerageLimitAmount ||
                      0) * 100
                  }
                />
                <Box mt={6}>
                  <Button
                    w="100%"
                    bgGradient="linear(to-r, #0c9ff6, #776bf0, #8a62ef)"
                    colorScheme="blue"
                    onClick={() => navigate("/mineMint")}
                  >
                    {t("home.buy")}
                  </Button>
                </Box>
              </Box>
              <Box mt={4} borderRadius="xl" bg="gray.700" p="4">
                <Text mt={4}>
                  {t("home.computing_package")}
                  {computingPackageData.amountTotal ? `(${computingPackageData.amountAlready || 0} / ${dynamicData.amountTotal})` : ""}
                </Text>
                <Progress
                  mt={2}
                  borderRadius="xl"
                  colorScheme="blue"
                  size="md"
                  value={(computingPackageData.amountAlready / computingPackageData.amountTotal || 0) * 100}
                />
                {/* <Box mt={6}>
                  <Button isDisabled w="100%" colorScheme="blue" onClick={() => navigate("/mineMint")}>
                    {t("home.withdraw")}
                  </Button>
                </Box> */}
                <Text mt={4} textAlign="center">
                   {computingPackageData.packageName || ""}
                </Text>
              </Box>
            </Box>

            <Box p={8} pt={0}>
              <Heading size="md" display="flex" alignItems="flex-end">
                {t("home.dm_price")}: &nbsp;
                <Text fontWeight="normal" fontSize="0.7em">
                  {dmmcPrice ? `$${dmmcPrice}` : ""}
                </Text>
              </Heading>

              <Box mt={4} borderRadius="xl" bg="gray.700" p="4">
                <LineChart data={priceData} />
              </Box>

              <Box mt={6}>
                <Text align="center" fontWeight="bold">
                  {/* 取消展示TVL */}
                  {/* TVL: {Number(tvl || 0).toLocaleString("zh")} USDT */}
                </Text>
                {/* <Button onClick={goTvl} w="100%" bgGradient="linear(to-r, #0c9ff6, #776bf0, #8a62ef)" align="center" py={2}>
                  {t("home.tvl_go")}
                </Button> */}
              </Box>
            </Box>

            {/* <Box p={8} pt={0}>
              <Heading size="md">队列总额</Heading>

              <Heading textAlign="center" mt={2} size="xl" className={cls.hightMoney}>
                $ 1009922
              </Heading>

              <Box mt={4}>
                <Button w="100%" onClick={() => navigate("/homeMatchQueue")}>
                  查看队列
                </Button>
              </Box>
            </Box> */}

            <VStack h="60px" />
          </Box>
        </Box>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent w="80vw" borderRadius="md" bg="gray.700">
          <ModalBody>
            <Heading pt={5} size="lg" textAlign="center">
              资产转换
            </Heading>
            <Grid mt={6} pb={4} templateRows="repeat(2, 1fr)" templateColumns="repeat(2, 1fr)" gap={3}>
              {entries.map((ele, index) => (
                <GridItem key={index} rowSpan={1}>
                  <CVEntry {...ele} />
                </GridItem>
              ))}
            </Grid>
            <ModalCloseButton />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Page;
